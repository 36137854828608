<template>
<b-card
    no-body
>
    <div class="m-2">
    <b-row>
      <b-col
        cols="12"
        md="9"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0 demo-inline-spacing"
        >
        
        <label>Entries</label>
        <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
        />
        <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-if="sortFav"
        @click="ChageFav"
        variant="warning"
        class="btn-icon"
      >
        <feather-icon icon="StarIcon" />
      </b-button>
      
       <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-else
         @click="ChageFav"
        variant="outline-warning"
        class="btn-icon"
      >
        <feather-icon icon="StarIcon" />
      </b-button>
       <b-button
        v-if="sortPublic"
      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
      variant="danger"
      @click="ChagePublic"
    >
      Public
    </b-button>
      <b-button
      v-else
      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
      variant="outline-danger"
       @click="ChagePublic"
    >
      Public
    </b-button>
          
        <b-button
            variant="primary"
            :to="{ name: 'enquiry-add'}"
        >
            Add Enquiry
        </b-button>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="filterLook"
            :options="optionsLooking"
            
            name="flavour-1"
          ></b-form-checkbox-group>
        </b-col>
        

        <!-- Search -->
        <b-col
        cols="12"
        md="3"
        >
        <div class="d-flex align-items-center justify-content-end demo-inline-spacing">
            <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1"
            placeholder="Enquiry/ID"
            />
            
        </div>
      </b-col>
    </b-row>
  </div>
  <b-table
    ref="refListTable"
    :items="fetchData"
    responsive
    striped
    :fields="tableColumns"
   
    :sort-by.sync="sortBy"
    show-empty
    empty-text="No matching records found"
    :sort-desc.sync="isSortDirDesc"
    class="position-relative"
  >
   <template slot="top-row" slot-scope="{ fields }">
     <td v-for="field in fields" :key="field.key">
       <b-form-input
          v-if="field.key == 'id'"
             v-model="filterId"
              :placeholder="field.label"
              style="width:50px;"
            />
        <b-form-input
          v-if="field.key == 'code'"
             v-model="filterCode"
              :placeholder="field.label"
            />
            <v-select
            v-if="field.key == 'status_id'"
            v-model="status_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statuses"
            :clearable="false"
            label='title'
            :reduce="option => option.id"
            class="d-inline-block w-100 fontsize-8"
        />
         <b-form-input
          v-if="field.key == 'place_name'"
             v-model="filterplace"
              placeholder="ชื่อโครงการ"
            />
           <b-form-input
          v-if="field.key == 'zone_name'"
             v-model="filterzone"
             :placeholder="field.label"
            />
             <b-form-input
          v-if="field.key == 'bedroom'"
             v-model="filterbr"
              style="width:50px;"
             :placeholder="field.label"
            />

            
        <b-form-input
          v-if="field.key == 'owner'"
             v-model="filterOwner"
              :placeholder="field.label"
            />
          <b-form-input
          v-if="field.key == 'min'"
             v-model="filterMin"
             style="width:80px;"
              :placeholder="field.label"
            />
              <b-form-input
          v-if="field.key == 'max'"
             v-model="filterMax"
              style="width:80px;"
              :placeholder="field.label"
            />
      </td>
   </template>
    <template #cell(is_public)="data">

      <b-form-checkbox
          v-if="data.item.is_public == 1"
         
          checked="true"
          class="custom-control-primary"
          name="is_public"
          switch
          @change="change_public(data.item.id,$event)"
        />
      <b-form-checkbox
          v-else
          checked="false"
          class="custom-control-primary"
          name="is_active"
          switch
          @change="change_public(data.item.id,$event)"
        />
    </template>
    <template #cell(sale)="data">
      {{ data.item.buy_min | getcomma }} - {{  data.item.buy_max | getcomma }} 
    </template>
     <template #cell(copy)="data">
       <b-link
        :to="{ name: 'enquiry-copy', params: { id: data.item.id } }"
        class="font-weight-bold d-block text-nowrap"
      >
      <feather-icon
            variant="primary"
        
              icon="CopyIcon"
              class="mr-50"
            />
        </b-link>
    </template>
    
    <template #cell(owner)="data">
     
       <b-link
        :to="{ name: 'clients-edit', params: { id: data.item.user_id } }"
        class="font-weight-bold d-block text-nowrap"
      >{{ data.item.firstname }} {{ data.item.lastname }}</b-link>
       <strong>Email : </strong> <a :href="'mailto:'+data.item.email">{{ data.item.email  }} </a><br />
        <strong>Phone : </strong><a :href="'tel:'+data.item.phone">{{ data.item.phone  }} </a>
    </template>
    <template #cell(rent)="data">
      {{ data.item.rent_min | getcomma }} - {{ data.item.rent_max | getcomma }} 
    </template>
    <template #cell(name)="data">
      <b-link
        :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
        class="font-weight-bold d-block text-nowrap"
      >
        {{ data.item.firstname }} {{ data.item.lasttname }}
      </b-link>
      <small class="text-muted">{{ data.item.email }}</small>
     
      
     
      

    </template>
   
    <template #cell(area)="data">
      {{ data.item.place_name }}
      {{ data.item.bedroom }}br

    </template>
    <template #cell(status_id)="data">
      <v-select
         
            v-model="data.item.status_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statuses"
            :clearable="false"
            label='title'
            :reduce="option => option.id"
            class="d-inline-block w-100 fontsize-8"
            @input="changeStatus(data.item)"
        />

    </template>
    <template #cell(min)="data">
      <span v-if="data.item.rent_min > 0">{{ data.item.rent_min | getcomma}}</span>
      <span v-if="data.item.buy_min > 0">{{ data.item.buy_min | getcomma}}</span>

    </template>
    <template #cell(max)="data">
      <span v-if="data.item.rent_max > 0">{{ data.item.rent_max | getcomma}}</span>
      <span v-if="data.item.buy_max > 0">{{ data.item.buy_max | getcomma}}</span>

    </template>
    <template #cell(code)="data">
        <b v-if="data.item.title">{{ data.item.title }}</b> <br  v-if="data.item.title" />
         <feather-icon
         stroke="#ff9f43"
          fill="#ff9f43"
           
              v-if="data.item.is_fav"
               @click="change_fav(data.item.id,false)"
              icon="StarIcon"
              class="mr-50"
            />
         <feather-icon
            variant="default"
              v-else
              @click="change_fav(data.item.id,true)"
              icon="StarIcon"
              class="mr-50"
            />
       <b-link
        :to="{ name: 'enquiry-edit', params: { id: data.item.id } }"
       
      >
        {{ data.item.code }} 
      </b-link>
      <b-badge v-if="data.item.looking == 'Rent'" variant="light-info">
            {{ data.item.looking }}
      </b-badge><br />
        <b-badge v-if="data.item.looking == 'Sale'" variant="light-success">
        {{ data.item.looking }}
      </b-badge>
      <b-link
        :to="{ name: 'matched-enquiry', params: { enquiry_id: data.item.id } }"
       
      >
        Match
      </b-link><br />
      <p v-if="data.item.comments[0]" class="text-truncate mb-0 text-warning width-200" >{{ filterTags(data.item.comments[0].comment) }}</p>
     <b-badge variant="light-secondary cursor-pointer" @click="formcomment_show(data.item.id)" >
            comment
          </b-badge>
    </template>
    <template #cell(actions)="data">    
      <span>
        <b-dropdown
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template v-slot:button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="text-body align-middle mr-25"
            />
          </template>
          <b-dropdown-item :to="{ name: 'enquiry-edit', params: { id: data.item.id } }"  v-if="data.item.created_by == userData.id || allow_delete.includes(userData.type_id)">
            <feather-icon
              icon="Edit2Icon"
              class="mr-50"
             
            />
            <span>Edit</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteconfirm(data.item.id)"   v-if="allow_delete.includes(userData.type_id)">
            <feather-icon
              icon="TrashIcon"
              class="mr-50"
            
            />
            <span>Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </span>
        
    </template>
  </b-table>
   <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totals"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>    
     <b-sidebar
        id="sidebar-right"
        bg-variant="white"
        v-model="isSidebarcommentOpen"
        no-header
        right
        backdrop
        shadow
        ref="formOwner"
        class="side-bar-responsive"
       
        >
        <template >
             <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          
                <h5
                  
                  class="mb-0"
                >
                 Comment
                </h5>
                <div>
                
                  <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="formcomment_close"
                  />
                </div>
                
          </div>
          
               <messages :id="eq_id" v-if="isSidebarcommentOpen" v-on:childToClose="onSubmitClose"  />
        
           
         </template>   
    </b-sidebar>
</b-card>

</template>
<script>
import {
  BCard, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,BImg,BFormSelect,BSidebar,BFormCheckboxGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'

import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

import useEnquiryList from './useEnquiryList'
import enquiryStoreModule from '../enquiryStoreModule'
import Ripple from 'vue-ripple-directive'
import Messages from '../../components/comments/Messages.vue'
import { filterTags, formatDate } from '@core/utils/filter'
export default {
    components: {
        BCard, 
        BRow, 
        BCol, 
        BFormInput, 
        BFormCheckbox, 
        BButton, 
        BTable, 
        BMedia, 
        BAvatar, 
        BLink,
        BBadge, 
        BDropdown, 
        BDropdownItem, 
        BPagination, 
        BTooltip,
        BImg,
        BFormSelect,
        vSelect,
        BSidebar,
        Messages,
        BFormCheckboxGroup
    },
    data(){
      return {
        isSidebarcommentOpen:false,
         userData: JSON.parse(localStorage.getItem('userData')),
         allow_delete:[1,2],
        eq_id:'',
        filterTags
      }
    },
    created(){
      console.log('userData',this.userData);
    },
    filters: {
    getcomma: function(text){
      if(!text){
        return ''

      }
			 var nStr = text;
			nStr += '';
			var x = nStr.split('.');
			var x1 = x[0];
			var x2 = x.length > 1 ? '.' + x[1] : '';
			var rgx = /(\d+)(\d{3})/;
			while (rgx.test(x1)) {
				x1 = x1.replace(rgx, '$1' + ',' + '$2');
			}
			return x1 ;
		},
  },
    setup(){
        const ENQUIRY_APP_STORE_MODULE_NAME = 'app-enquiry'
        if (!store.hasModule(ENQUIRY_APP_STORE_MODULE_NAME)) store.registerModule(ENQUIRY_APP_STORE_MODULE_NAME, enquiryStoreModule)
        onUnmounted(() => {
            if (store.hasModule(ENQUIRY_APP_STORE_MODULE_NAME)) store.unregisterModule(ENQUIRY_APP_STORE_MODULE_NAME)
        })
        const {
            fetchData,
      
            tableColumns,
            perPage,
            currentPage,
            totals,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            sortFav,
            sortPublic,
            ChagePublic,
            optionsLooking,
            isSortDirDesc,
            refListTable,
             filterMin,
        filterMax,
            filterCode,
            filterplace,
            filterLook,
            filterzone,
            filterbr,
            filterOwner,
            filterId,
            refetchData,
            UpdateFav,
            UpdatePublic,
           deleteData,ChageFav,
           statuses,
           status_id,
           changeStatus
        } = useEnquiryList()
         return {
             fetchData,
            filterCode,
            filterplace,
            filterzone,
            filterbr,
            filterLook,
            tableColumns,
            perPage,
            currentPage,
            optionsLooking,
            totals,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refListTable,
            filterOwner,
            filterId,
             filterMin,
        filterMax,
        sortFav,
        sortPublic,
        ChagePublic,
            deleteData,
            UpdateFav,
            UpdatePublic,
            refetchData,ChageFav,
            statuses,status_id,
            changeStatus
         }
         

    },
    directives: {
    Ripple,
  },
   methods:{
    async change_fav(id,is_fav){
      console.log(id,is_fav);
      let isFav = is_fav?1:0
      this.UpdateFav(id,isFav);
      this.refetchData();
    

    },
    async change_public(id,is_public){
      let isPublic = is_public?1:0
      this.UpdatePublic(id,isPublic);
      this.refetchData();
    

    },
    onSubmitClose(){  
      this.isSidebarcommentOpen = false;
      this.refetchData();
    },
    async formcomment_show(id){
      this.eq_id = id;
      this.isSidebarcommentOpen=true;
    },
    formcomment_close(){
      this.isSidebarcommentOpen=false;
    },
    deleteconfirm(id){
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Property ID.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value === true){
            this.deleteData(id)
          }
          console.log('value',value);
          //this.deleteData(id)
        })
      
    }
  },
  
}
</script>
<style lang="scss">
.fontsize-8{
  font-size: 8px;
}
</style>