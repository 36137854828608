import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useEnquiryList() {
    const toast = useToast()
    const refListTable = ref(null)
    const tableColumns = [
        //{ key: 'id',label:'ID', sortable: false, tdClass: 'text-center'  },
        { key: 'copy',label:'Copy', sortable: false, tdClass: 'text-center' },
        { key: 'code', label: 'Enquiry Code', sortable: false },
        { key: 'owner',label:'Client', sortable: false },
        { key: 'status_id',label:'Status', sortable: false, thStyle: 'width:185px;' },
        { key: 'place_name',label:'ชื่อโครงการ', sortable: false },
        { key: 'zone_name',label:'zone', sortable: false },
        { key: 'bedroom',label:'Br', sortable: false },
        { key: 'min',label:'Min', sortable: false, tdClass: 'text-center'  },
        { key: 'max',label:'Max', sortable: false, tdClass: 'text-center'  },

       // { key: 'sale',label:'Rent Budget', sortable: false },
        //{ key: 'rent',label:'Sale Budget', sortable: false },
        { key: 'is_public',label:'Public', sortable: false },
      
       
        
        { key: 'actions' }       
    ]
    const perPage = ref(10)
    const totals = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const optionsLooking = [
      { text: 'Rent', value: 'Rent' },
      { text: 'Sale', value: 'Sale' }
    ]
    const statuses = ref([]);
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const filterCode = ref(null);
    const filterplace = ref(null);
    const filterzone = ref(null);
    const filterLook = ref([]);
    const status_id = ref('0');
    const filterbr = ref(null);
    const filterOwner = ref(null);
    const filterId = ref(null);
    const filterMin = ref(null);
    const filterMax = ref(null);
    const sortPublic = ref(null);
    const sortFav = ref(null);
    
    
    const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totals.value,
        }
    })
   
  
   
   
    
    const refetchData = () => {
    
        refListTable.value.refresh()
    }
    const ChageFav = () => {
      sortFav.value=sortFav.value?null:1;
      refListTable.value.refresh()
  }
  const ChagePublic = () => {
    sortPublic.value=sortPublic.value?null:1;
    refListTable.value.refresh()
}
  
    watch([currentPage, perPage, searchQuery,filterMin,filterMax, filterCode, filterplace, filterzone, filterbr, filterLook,filterOwner,filterId,status_id], () => {
      
        refetchData()
    })
    const fetchData = (ctx, callback)=>{
      const looking = filterLook.value.map(filterLook => `"${filterLook}"`);
      console.log('filterLook',looking);
      const looks = looking.join(',');
       
        const offset = perPage.value*(currentPage.value-1);
        let created_by = '';
        const userData = JSON.parse(localStorage.getItem('userData'));
        const permission = userData.ability.findIndex(s => s.action === 'manage' && s.subject === 'all');
   
        if(permission < 0 && sortPublic.value == null){
          created_by = userData.id
        }
        
        store
        .dispatch('app-enquiry/fetchData', {
            perpage: perPage.value,
            offset:offset,
            code:filterCode.value,
            owner:filterOwner.value,
            place_name:filterplace.value,
            zone_name:filterzone.value,
            bedroom:filterbr.value,
            keyword:searchQuery.value,
            priceMin:filterMin.value,
            priceMax:filterMax.value,
            qId: filterId.value,
            sortFav:sortFav.value,
            isPublic:sortPublic.value,
            created_by:created_by,
            filterLook:looks,
            status_id:status_id.value=='0'?'':status_id.value
        })
        .then(response => {
            
            
            const data = response.data.data;
           
            totals.value = response.data.total
            console.log('enq', data);
        //  const { invoices, total } = response.data
            callback(data)
            // callback(invoices)
            //totalInvoices.value = total
        })
        .catch(() => {
            toast({
            component: ToastificationContent,
            props: {
                title: "Error fetching Owner' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
            },
            })
        })
    }
    const getStatuses = async()=>{
      const defs = [{id:'0',title:'All'}];
      const status = await store.dispatch('app-enquiry/fetchgetStatuses',{});
     // console.log('enq-statuses',status.data);
      statuses.value = [...defs, ...status.data.data];
      console.log('enq-status',statuses.value);

    }
    getStatuses();
    
    const UpdateFav = (id,is_fav)=>{
      store.dispatch('app-enquiry/fetchUpdateFav',{
        id: id,
        is_fav:is_fav, 
    }).then(response=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Update Enquiry Pin  Success",
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        refetchData();
      }).catch(()=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Error Update Enquiry Pin",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
  
      })

    }
    const UpdatePublic = (id,is_public)=>{
      store.dispatch('app-enquiry/fetchUpdatePublic',{
        id: id,
        is_public:is_public, 
    }).then(response=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Update Public  Success",
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        refetchData();
      }).catch(()=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Error Update Public",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
  
      })

    }
    const changeStatus = async(item)=>{
     
    const response =  await store.dispatch('app-enquiry/fetchchangeStatuses',item);
    if(response.data.success){
      toast({
        component: ToastificationContent,
        props: {
          title: "Update Status Successs",
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })

      refetchData()
    }else{
      toast({
        component: ToastificationContent,
        props: {
          title: "Error Update status",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      refetchData()
      
    }
    

    }
    const deleteData = (id) => {
        //fetchDeleteProperty
        store.dispatch('app-enquiry/fetchDeleteData',id).then(response=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Delete Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData();
        }).catch(()=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Delete Property",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })
        //console.log('id', id);
        
      }
    
    return {
        fetchData,
      
        tableColumns,
        perPage,
        currentPage,
        totals,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        filterLook,
        isSortDirDesc,
        refListTable,
        filterCode,
        filterplace,
        filterzone,
        filterbr,
        filterOwner,
        filterId,
        filterMin,
        filterMax,
        UpdateFav,
        UpdatePublic,
        deleteData,
        refetchData,
        ChageFav,
        sortPublic,
        optionsLooking,
        ChagePublic,
        sortFav,
        statuses,
        status_id,
        changeStatus
    }
}