import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchDeleteData(ctx, id){
            return new Promise((resolve, reject) => {
                axios
                  .delete('/enquiry/v1/delete/'+id)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        fetchgetStatuses(ctx, queryParams){
            var data = JSON.stringify(queryParams);
            return new Promise((resolve, reject) => {
                axios
                  .post('/enquiry/v1/status/getall',data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        fetchchangeStatuses(ctx, queryParams){
            var data = JSON.stringify(queryParams);
            return new Promise((resolve, reject) => {
                axios
                  .put('/enquiry/v1/status/change/'+queryParams.id,data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        fetchUpdateFav(ctx, queryParams){
            var data = JSON.stringify(queryParams);
            return new Promise((resolve, reject) => {
                axios
                  .put('/enquiry/v1/updatefav/'+queryParams.id,data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        fetchUpdatePublic(ctx, queryParams){
            var data = JSON.stringify(queryParams);
            return new Promise((resolve, reject) => {
                axios
                  .put('/enquiry/v1/updatepublic/'+queryParams.id,data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        
        fetchData(ctx, queryParams){
            var data = JSON.stringify(queryParams);
            return new Promise((resolve, reject) => {
          
                axios
                .post('/enquiry/v1/lists', data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        },
        
    }
}